<script>
import Layout from "../../layouts/main";
import PageHeader from "@/components/page-header";
import Multiselect from "vue-multiselect";
import CKEditor from "@ckeditor/ckeditor5-vue";
import ClassicEditor from "@ckeditor/ckeditor5-build-classic";
import { apiRequest } from "@/helpers/api-call";
import { format } from "date-fns";
import router from "@/router/index.js";
import Vue from "vue";

/**
 * Orders Component
 */
export default {
  components: {
    Layout,
    PageHeader,
    Multiselect,
    ckeditor: CKEditor.component,
  },
  data() {
    return {
      title: "liste des utilisateurs",
      items: [
        {
          text: "Dashboard",
        },
        {
          text: "liste",
          active: true,
        },
      ],
      tyMedia: [
        {
          name: "Texte",
          value: "text",
        },
        {
          name: "Image",
          value: "image",
        },
        {
          name: "Vidéo",
          value: "video",
        },
      ],
      tyMessage: [
        {
          name: "Notification",
          value: 0,
        },
        {
          name: "Mail",
          value: 1,
        },
        {
          name: "Notification + Mail",
          value: 2,
        },
      ],

      userTypeList: [
        {
          id: "CONDUCTEUR",
          value: "MOTO",
        },
        {
          id: "PARTICULIER",
          value: "VOITURE",
        },
        {
          id: "TAXIMAN",
          value: "TAXI",
        },
        {
          id: "SOCIETE",

          value: "BUS",
        },
        { id: "PASSAGER", value: "PASSAGER" },
      ],
      url_link: "",
      userTypeSelect: "",
      messageSelect: null,
      mediaSelect: null,
      messageContent: [
        {
          sujet: "",
          message: "",
          userId: "",
        },
      ],
      value: null,
      value1: null,
      options: ["Sexe", "Taille", "Vehicule", "je vois"],
      idUser: 0,
      dataUser: [],
      dataUserD: [],
      rowsdataUser: 0,
      rowsdataUserD: 0,
      nbreUser: "",
      totalRows: 1,
      changeCurrent: 1,
      changePerPage: 100,
      currentPage: 1,
      currentPageD: 1,
      perPage: 100,
      perPageD: 100,
      pageOptions: [100, 250, 500],
      pageOptionsD: [100, 250, 500],
      filter: null,
      filterOn: [],
      sortBy: "orderid",
      sortDesc: false,
      paginatedDate: [],
      fields: [
        { key: "username", sortable: true, label: "Utilisateur" },
        { key: "tel", sortable: true, label: "Téléphone" },
        { key: "pays", sortable: true, label: "Pays" },
        //{ key: "type", sortable: true, label: "Type" },
        { key: "trajet", sortable: true, label: "Trajets Publiés" },
        { key: "dateIns", sortable: true, label: "Inscription" },
        { key: "manuel", sortable: true, label: "Manuel" },
        { key: "badge", label: "Badge", sortable: true },
        { key: "action", label: "Actions" },
      ],
      fieldsD: [
        { key: "username", sortable: true, label: "Utilisateur" },
        { key: "tel", sortable: true, label: "Téléphone" },
        { key: "pays", sortable: true, label: "Pays" },
        //{ key: "type", sortable: true, label: "Type" },
        { key: "trajet", sortable: true, label: "Trajets Publiés" },
        { key: "dateIns", sortable: true, label: "Inscription" },
        { key: "dateSup", sortable: true, label: "Supprimé le" },
        { key: "dateSus", sortable: true, label: "Suspendu le" },
        { key: "manuel", sortable: true, label: "Manuel" },
        { key: "badge", label: "Badge", sortable: true },
        { key: "action", label: "Actions" },
      ],
      showDisable: false,
      startValue: 1,
      endValue: 500,
      totalValue: "0",
      showDelete: false,
      showEdit: false,
      showSucess: false,
      showEchec: false,
      showSucessEdit: false,
      showEchecEdit: false,
      userToUse: 0,

      infoUser: {
        id: "",
        firstname: "",
        lastname: "",
        profession: "",
        birthday: "",
        adresse: "",
        ville: "",
        pays: "",
        userType: "",
        tel: "",
        email: "",
        personneUrgence: "",
        contactUrgence: "",
        name: "",
        boitePostal: "",
        apropos: "",
        permisCategorie: "",
        permisAnnee: "",
        permisNumuero: "",
        indicatifTel: "",
        indicatifContactUrg: "",
      },
      userTypeLocal: null,
      showMessage: false,
      showMessageMail: false,
      editor: ClassicEditor,
      editorData: "",
      deleteModal: false,
      userChargeLoader: false,
      newDataUser: [],
      newStart: false,
      recherche: "",
      activeConducteur: false,
      onlyConducteur: [],
    };
  },
  computed: {
    /**
     * Total no. of records
     */
    rows() {
      return this.dataUser.length;
    },
    rowsinactif() {
      return this.dataUserD.length;
    },
  },
  async mounted() {
    this.init();
    this.inactif();
    this.userTotal();
    /*  const dots = document.getElementById('#loaading__dots')
  const addAnimate = ()=>{
    dots.classList.add('animate')
    setTimeout(()=>{
      dots.classList.remove('aniamte');
      setTimeout(()=>{
        addAnimate()
      },100)
    }, 2600)


    }
    addAnimate(); */
  },
  methods: {
    async init() {
      //Chargement des données
      const data = await apiRequest("GET", "admin/users", undefined, false);
      if (data && data.data) {
        const formattedTable = data.data.map((user) => {
          var nom = user.lastname == "" ? "Nom" : user.lastname;
          var prenom = user.firstname == "" ? "Prénom" : user.firstname;
          /*var type = user.userType == "" ? "" : user.userType;
          if (user.userType == "PARTICULIER" || user.userType == "CONDUCTEUR") {
            if (!user.vehicule) {
              type = "PASSAGER";
            } else if (user.userType == "CONDUCTEUR") {
              type = "C. MOTO";
            } else if (user.userType == "PARTICULIER") {
              type = "C. VOITURE";
            }
          }
          if (user.userType == "TAXIMAN") {
            type = "TAXI";
          }
          if (user.userType == "SOCIETE") {
            type = "BUS";
          }*/

          return {
            id: user.id,
            username: nom + " " + prenom,
            firstname: user.firstname,
            lastname: user.lastname,
            pays: user.pays,
            tel: user.tel && user.tel != "" ? user.indicatifTel + user.tel : "",
            email: user.email,
            trajet: user.nombreTrajet,
            dateIns: new Date(user.dateCreation).toLocaleString("fr-FR"),
            badge: user.userValidated,
            //type: type,
            statut: user.active ? "Actif" : "Suspendu",
            manuel: user.uid ? "Non" : "Oui",
          };
        });
        this.dataUser = formattedTable;
        this.rowsdataUser = formattedTable.length;
        this.totalRows = formattedTable.length;
      }
    },
    async actif(page, limit) {
      //Chargement des données
      this.userChargeLoader=true;
      const data = await apiRequest(
        "GET",
        "admin/users?page=" + page + "&limit=" + limit,
        undefined,
        false
      );
      if (data && data.data) {
        const formattedTable = data.data.map((user) => {
          var nom = user.lastname == "" ? "Nom" : user.lastname;
          var prenom = user.firstname == "" ? "Prénom" : user.firstname;
          return {
            id: user.id,
            username: nom + " " + prenom,
            firstname: user.firstname,
            lastname: user.lastname,
            pays: user.pays,
            tel: user.tel && user.tel != "" ? user.indicatifTel + user.tel : "",
            email: user.email,
            trajet: user.nombreTrajet,
            dateIns: new Date(user.dateCreation).toLocaleString("fr-FR"),
            badge: user.userValidated,
            //type: type,
            statut: user.active ? "Actif" : "Suspendu",
            manuel: user.uid ? "Non" : "Oui",
          };
        });
        this.userChargeLoader = false;
        this.newDataUser = formattedTable;
        this.rowsdataUser = formattedTable.length;

        this.totalRows = formattedTable.length;
      }
    },

    async userTotal() {
      const datatotal = await apiRequest(
        "GET",
        "admin/user-total",
        undefined,
        false
      );
      if (datatotal && datatotal.data) {
        this.totalValue = datatotal.data.total;
        this.totalRows = datatotal.data.total;
      }
    },

    async userSearch() {
      this.userChargeLoader = true;
      const dataSearch = await apiRequest(
        "POST",
        "admin/user-recherche",

        { mots: this.recherche },
        false
      );
      if (dataSearch && dataSearch.data) {
        this.paginatedDate = [];
        console.log(dataSearch.data);
        const formattedTable = dataSearch.data.map((user) => {
          var nom = user.lastname == "" ? "Nom" : user.lastname;
          var prenom = user.firstname == "" ? "Prénom" : user.firstname;
          return {
            id: user.id,
            username: nom + " " + prenom,
            firstname: user.firstname != null ? user.firstname : "",
            lastname: user.lastname != null ? user.lastname : "",
            pays: user.pays != null ? user.pays : "",
            tel: user.tel && user.tel != "" ? user.indicatifTel + user.tel : "",
            email: user.email != null ? user.email : "",
            trajet: user.nombreTrajet != null ? user.nombreTrajet : "",
            dateIns: new Date(user.dateCreation).toLocaleString("fr-FR"),
            badge: user.userValidated != null ? user.userValidated : "",
            //type: type,
            statut: user.active ? "Actif" : "Suspendu",
            manuel: user.uid ? "Non" : "Oui",
          };
        });
        this.newDataUser = formattedTable;
        console.log(this.newDataUser);
        this.rowsdataUser = formattedTable.length;
        this.totalRows = formattedTable.length;

        this.userChargeLoader = false;
      }
    },

    async inactif() {
      const dataD = await apiRequest(
        "GET",
        "admin/users-inactif",
        undefined,
        false
      );
      if (dataD && dataD.data) {
        console.log('dataD.data' ,dataD.data)
        const formattedTableD = dataD.data.map((user) => {
          var nom = user.lastname == "" ? "Nom" : user.lastname;
          var prenom = user.firstname == "" ? "Prénom" : user.firstname;
          /*var type = user.userType == "" ? "" : user.userType;
          if (user.userType == "PARTICULIER" || user.userType == "CONDUCTEUR") {
            if (!user.vehicule) {
              type = "PASSAGER";
            } else if (user.userType == "CONDUCTEUR") {
              type = "C. MOTO";
            } else if (user.userType == "PARTICULIER") {
              type = "C. VOITURE";
            }
          }
          if (user.userType == "TAXIMAN") {
            type = "TAXI";
          }

          if (user.userType == "SOCIETE") {
            type = "BUS";
          }*/
          return {
            id: user.id,
            username: nom + " " + prenom,
            firstname: user.firstname,
            lastname: user.lastname,
            pays: user.pays,
            tel: user.tel,
            email: user.email,
            trajet: user.nombreTrajet,
            dateSup:user.deletedAt ? new Date(user.deletedAt).toLocaleDateString("fr-FR") : "non défini",
            dateSus:user.dateSuspension ? new Date( user.dateSuspension).toLocaleString("fr-FR") +" "+new Date( user.dateSuspension).toLocaleTimeString("fr-FR") : "non défini",
            dateIns: new Date(user.dateCreation).toLocaleString("fr-FR"),
            //dateDes: new Da
            badge: user.userValidated,
            //type: type,
            statut: user.active ? "Actif" : "Suspendu",
            manuel: user.uid ? "Non" : "Oui",
          };
        });

        this.dataUserD = formattedTableD.sort((a,b)=>{a.dateSup && b.dateSup ? new Date( b.dateSup) - new Date(a.dateSup) : new Date(a)  - new Date(b)});
        this.rowsdataUserD = formattedTableD.length;

        // Set the initial number of items
        this.totalRows = this.dataUser.length;
        this.nbreUser = this.dataUser.length;
      }
    },
    async addMessage() {
      this.showMessage = false;
      this.showMessageMail = false;

      const data = await apiRequest(
        "POST",
        "admin/message-user",
        {
          idUser: this.messageContent.userId,
          typeMessage: this.messageSelect.value,
          subject: this.messageContent.sujet,
          text: this.messageContent.message,
          media_type: this.mediaSelect.value,
        },
        false
      );

      if (data && data.data) {
        this.init();


        this.$toast.success("Opération effectuée avec succès", {
  position: "bottom-right",
  timeout: 2000,
  closeOnClick: true,
  pauseOnFocusLoss: true,
  pauseOnHover: true,
  draggable: true,
  draggablePercent: 0.6,
  showCloseButtonOnHover: false,
  hideProgressBar: true,
  closeButton: "button",
  icon: true,
  rtl: false
});
      } else {
 
        this.$toast.success("L'envoi a échoué", {
  position: "bottom-right",
  timeout: 2000,
  closeOnClick: true,
  pauseOnFocusLoss: true,
  pauseOnHover: true,
  draggable: true,
  draggablePercent: 0.6,
  showCloseButtonOnHover: false,
  hideProgressBar: true,
  closeButton: "button",
  icon: true,
  rtl: false
});
        this.init();
      }
    },
    async ModifSubmit() {
      this.showEdit = false;
      const Editdata = await apiRequest(
        "PUT",
        "users/" + this.infoUser.id,
        {
          idUser: this.infoUser.id,
          lastname: this.infoUser.lastname,
          firstname: this.infoUser.firstname,
          tel: this.infoUser.telephone,
          indicatifTel: this.infoUser.indicatifTel,
          email: this.infoUser.email,

          ville: this.infoUser.ville,
          pays: this.infoUser.pays,
          name: this.infoUser.name,
          boitePostal: this.infoUser.boitePostal,
          birthday: this.infoUser.birthday,
          userType: this.userTypeLocal.id,
          profession: this.infoUser.profession,
          apropos: this.infoUser.apropos,
          permisCategorie: this.infoUser.permisCategorie,
          permisAnnee: this.infoUser.permisNumuero,
          permisNumuero: this.infoUser.permisNumuero,
          personneUrgence: this.infoUser.personneUrgence,
          contactUrgence: this.infoUser.contactUrgence,
          indicatifContactTel: this.infoUser.indicatifContactUrg,
      
        },
        false
      );

      if (Editdata && Editdata.data) {
        this.init();
      

        this.$toast.success("Utilisateur modifié avec succès", {
  position: "bottom-right",
  timeout: 2000,
  closeOnClick: true,
  pauseOnFocusLoss: true,
  pauseOnHover: true,
  draggable: true,
  draggablePercent: 0.6,
  showCloseButtonOnHover: false,
  hideProgressBar: true,
  closeButton: "button",
  icon: true,
  rtl: false
});
      } else {
     

        this.$toast.error("La modification a échouée", {
  position: "bottom-right",
  timeout: 2000,
  closeOnClick: true,
  pauseOnFocusLoss: true,
  pauseOnHover: true,
  draggable: true,
  draggablePercent: 0.6,
  showCloseButtonOnHover: false,
  hideProgressBar: true,
  closeButton: "button",
  icon: true,
  rtl: false
});

        this.init();
      }
    },
    EditModal() {
      this.submitted = false;
      this.showEdit = false;
    },
    async editClick(row) {
      this.showEdit = true;
      this.infoUser.id = row.id;
      const dataDetail = await apiRequest(
        "POST",
        "admin/detail-user",
        { id: row.id },
        false
      );

      if (dataDetail && dataDetail.data) {
        this.infoUser.lastname = dataDetail.data.personne.lastname;
        this.infoUser.firstname = dataDetail.data.personne.firstname;
        this.infoUser.profession = dataDetail.data.personne.profession;
        (this.infoUser.birthday = format(
          new Date(dataDetail.data.personne.birthday),
          "dd/MM/yyyy"
        )),
          (this.infoUser.adresse = dataDetail.data.personne.adresse);
        this.infoUser.ville = dataDetail.data.personne.ville;
        this.infoUser.pays = dataDetail.data.personne.pays;
        this.infoUser.email = dataDetail.data.personne.email;
        this.infoUser.contactUrgence = dataDetail.data.personne.contactUrgence;
        this.infoUser.personneUrgence =
          dataDetail.data.personne.personneUrgence;
        this.infoUser.telephone = dataDetail.data.personne.tel;
        this.infoUser.userTypeSelect = dataDetail.data.userType;
        this.userTypeLocal = dataDetail.data.userType;
        this.infoUser.indicatifTel = dataDetail.data.personne.indicatifTel;
        this.infoUser.indicatifContactUrg =
          dataDetail.data.personne.indicatifContactTel;
      }
    },
    desactiverUser(row) {
      this.userToUse = row.id;

      this.showDelete = true;
    },

    async deleteUser(){

      const response = await apiRequest('POST','admin/supprimer-user',{idUser:this.userToUse});
      console.log(response)
      response.data && response.status ==200 ?( this.showDelete=false,this.inactif(), this.$toast.success('Opération réussi')):  (this.showDelete=false , this.$toast.error('Opération échoué'), this.inactif())
    },
    async desactiveUser() {
      this.showDelete = false;
      const data = await apiRequest(
        "POST",
        "admin/suspendre-user",
        { idUser: this.userToUse },
        false
      );

      if (data && data.data) {
  

        this.$toast.success("Utilisateur désactivé avec succès !!!", {
  position: "bottom-right",
  timeout: 2000,
  closeOnClick: true,
  pauseOnFocusLoss: true,
  pauseOnHover: true,
  draggable: true,
  draggablePercent: 0.6,
  showCloseButtonOnHover: false,
  hideProgressBar: true,
  closeButton: "button",
  icon: true,
  rtl: false
});

        this.init();
      }
    },

    messageShow(row, typeMessage) {
      this.messageSelect = typeMessage;

      if (typeMessage.value == 0) this.showMessage = true;
      if (typeMessage.value == 1) this.showMessageMail = true;
      this.messageContent.userId = row.id;
    },
    async activeUser() {
      const data = await apiRequest(
        "POST",
        "admin/activer-user",
        { idUser: this.userToUse },
        false
      );

      if (data && data.data) {

        this.$toast.success("Utilisateur activé avec succès !!!", {
  position: "bottom-right",
  timeout: 2000,
  closeOnClick: true,
  pauseOnFocusLoss: true,
  pauseOnHover: true,
  draggable: true,
  draggablePercent: 0.6,
  showCloseButtonOnHover: false,
  hideProgressBar: true,
  closeButton: "button",
  icon: true,
  rtl: false
});
        this.deleteModal = false;
        this.init();
      }
    },
    deleteUserM(row) {
      this.userToUse = row.id;
      this.showDelete = true;
    },
    setDepartContry: function (place) {
      //console.log('place:', place)
      if (place) {
        //console.log("place:", place);
        /*console.log('place.formatted_address:', place.formatted_address)*/
        this.infoUser.ville = place.formatted_address;
      }
    },

    rowClass(item, type) {
      if (!item || type !== "row") return;
      if (item.badge === true) return "table-warning";
    },
    activeUserPop(row) {
      this.userToUse = row.id;
      this.deleteModal = true;
    },
    position() {
      Vue.swal({
        position: "center-end",
        icon: "success",
        title: "Mise à jour effectuée",
        showConfirmButton: false,
        timer: 3000,
      });
    },
   onFiltered (filteredItems) {
      this.totalRows = filteredItems.length;
      this.currentPage = 1;
    },
    goToDetail(row) {
      router.push(`/user/user_info/${row.id}`);
    },
    openNewTab(row) {
      window.open(`https://adminrmobility.raynis.co/user/user_info/${row.id}`, '_blank');
    },

    decrement() {
      this.userChargeLoader = true;

      this.startValue -= 500;
      this.endValue -= 500;
      console.log("userChargeLoader", this.userChargeLoader);
      this.actif(this.startValue, this.endValue);
      this.activeConducteur = false;
    },

    increment() {
      this.userChargeLoader = true;
      this.startValue += 500;
      this.endValue += 500;
      console.log("userChargeLoader", this.userChargeLoader);
      this.actif(this.startValue, this.endValue);
      this.activeConducteur = false;
    },
    incrementToLast() {
      this.userChargeLoader = true;
      this.startValue = this.totalValue - 500;
      this.endValue = this.totalValue;
      console.log("userChargeLoader", this.userChargeLoader);
      this.actif(this.startValue, this.endValue);
      this.activeConducteur = false;
    },
    firstVal() {
      this.userChargeLoader = true;
      this.startValue = 1;
      this.endValue = 500;
      console.log("userChargeLoader", this.userChargeLoader);
      this.actif(this.startValue, this.endValue);
      this.activeConducteur = false;
    },
  },

  watch: {
    activeConducteur(newValue, oldValue) {
      if (newValue === true) {
        this.userChargeLoader=true
        console.log("Old", oldValue);

   
        if (this.newDataUser.length != 0) {
          this.userChargeLoader=true
          this.dataUser = [];
          this.newDataUser.forEach((element) => {
            if (element.trajet >= 1) {
              this.dataUser.push(element);
            }
          });

          this.newDataUser= this.dataUser  
          this.userChargeLoader=false
        }
        else{
          this.userChargeLoader=true
          this.dataUser.forEach((element) => {
            if (element.trajet >= 1) {
              this.newDataUser.push(element);
            }
          });
          this.dataUser=[]
          this.userChargeLoader=false
        }



      } else {
        if (this.newDataUser.length != 0) {
          this.dataUser = [];
          this.actif(this.startValue, this.endValue);
          this.userChargeLoader=false;
        } else {
          console.log("non non non");
        
          this.init();
          this.userChargeLoader=false;
        }
      }
    },
  },
};

/* const dots = document.querySelector('.loaading__dots')
  const addAnimate = ()=>{
    dots.classList.add('animate')
    setTimeout(()=>{
      dots.classList.remove('aniamte');
      setTimeout(()=>{
        addAnimate()
      },100)
    }, 2600)


    }
    addAnimate(); */
</script>

<template>
  <Layout>
    <PageHeader :title="title" :items="items" />
    <div class="row rowBase">
      <div class="col-lg-12">
        <div class="card homeContainer">
          <div class="containerDot" v-if="dataUser.length == 0 && newDataUser.length==0">
            <span class="loading">Chargement en cours... </span>
            <!--  <div class="dots">
              <div class="dot dot-1"></div>
              <div class="dot dot-2"></div>
              <div class="dot dot-2"></div>
            </div> -->
          </div>
          <div
            class="d-flex align-items-center otherThing"
            v-if="userChargeLoader === true"
          >
            <span class="loading">Chargement en cours...</span>
            <div aria-hidden="true"></div>
          </div>

          <div class="card-body pt-0">
            <div>
              <h4 class="card-title mt-4">
                 Total d'utilisateurs : {{ totalRows }}
              </h4>
            </div>
            <transition mode="out-in">
              <div
                class="dataTables_paginate paging_simple_numbers float-right"
              >
                <ul class="pagination pagination-rounded mb-0">
                  <!-- pagination -->
                  <li>
                    <span class="interval"> {{ startValue }}</span> -
                    <span class="interval">{{ endValue }}</span> sur
                    {{ totalValue }}
                  </li>
                  &nbsp;&nbsp;&nbsp;&nbsp;
                  <li>
                    <button
                      class="myBouton"
                      @click="firstVal()"
                      :disabled="startValue == 1"
                      data-toggle="tooltip"
                      data-placement="bottom"
                      title="Prem"
                    >
                      <ion-icon name="play-skip-back-outline"></ion-icon>
                    </button>
                    <button
                      type="button"
                      class="myBouton"
                      @click="decrement()"
                      :disabled="endValue == 500"
                      data-toggle="tooltip"
                      data-placement="bottom"
                      title="Préc"
                    >
                      <ion-icon name="chevron-back-outline"></ion-icon>
                    </button>
                    <button
                      class="myBouton"
                      @click="increment()"
                      data-toggle="tooltip"
                      data-placement="bottom"
                      title="Suiv"
                    >
                      <ion-icon name="chevron-forward-outline"></ion-icon>
                    </button>
                    <button
                      class="myBouton"
                      @click="incrementToLast()"
                      data-toggle="tooltip"
                      data-placement="bottom"
                      title="Dern"
                    >
                      <ion-icon name="play-skip-forward-outline"></ion-icon>
                    </button>
                  </li>
                </ul>
              </div>
            </transition>
            <b-tabs nav-class="nav-tabs-custom">
              <b-tab title-link-class="p-3">
                <template v-slot:title>
                  <a class="font-weight-bold active"
                    >Liste des utilisateurs : {{ totalValue }}
                  </a>
                </template>
                <div class="row d-flex align-items-center mt-4 mb-3">
                  <div class="col-md-3 text-left">
                    <span class="superuser mb-1"></span>&nbsp; Superuser
                  </div>
                  <div class="col-md-3">
                    <div class="center">
                      <span class="mode">Tout</span>&nbsp;&nbsp;
                      <input
                        type="checkbox"
                        v-model="activeConducteur"
                        :checked="activeConducteur"
                      />&nbsp;&nbsp;
                      <span class="mode">Conducteur</span>
                    </div>
                  </div>
                  <!-- Search -->
                  <div class="col-sm-12 col-md-6">
                    <div
                      class="inputCol"
                    >
                      <!--   <label class="d-inline-flex align-items-center">
                        Rechercher:
                        <b-form-input
                          v-model="filter"
                          type="search"
                          class="form-control form-control-md ml-2"
                        ></b-form-input>
                      </label> -->

                      <div class="input mb-2">
                        <input
                          type="search"
                          id="serchInput"
                          placeholder="Entrez un mot clé..."
                          v-model="recherche"
                          @keyup.enter="userSearch"
                        />
                      </div>
                    </div>
                  </div>
                  <!-- End search -->
                </div>
                <div class="table-responsive mt-2">
                  <b-table
                    id="trajet"
                    style="cursor: pointer"
                    class="table-centered"
                    :items="newDataUser.length != 0 ? newDataUser : dataUser"
                    :fields="fields"
                    responsive="sm"
                    :sort-by.sync="sortBy"
                    :sort-desc.sync="sortDesc"
                    :filter="filter"
                    :filter-included-fields="filterOn"
                    @filtered="onFiltered"
                    @row-clicked="goToDetail"
                    :tbody-tr-class="rowClass"
                  >

                  <template v-slot:cell(username)="row">

                    <div>

                      {{ row.value }}
                      <!-- <span class="label label-warning">Taxi</span> -->
                      <!-- &nbsp;<span class="badge badge-pill badge-success m-2" style="font-size: 100%;background-color: goldenrod; color: #fff;" >Taxi</span> -->


                    </div>

                  </template>
                    <template v-slot:cell(badge)="row">
                      <div
                        class="py-2 d-flex justify-content-center"
                        :class="{
                          'fa fa-star': `${row.value}` === 'false',
                          'fa fa-star badge-soft-warning':
                            `${row.value}` === 'true',
                        }"
                      ></div>
                    </template>
                    <template v-slot:cell(statut)="row">
                      <div
                        class="py-2 d-flex justify-content-center badge font-size-12"
                        :class="{
                          'badge-soft-danger': `${row.value}` === 'Suspendu',
                          'badge-soft-success': `${row.value}` === 'Actif',
                        }"
                      >
                        {{ row.value }}
                      </div>
                    </template>
                    <template v-slot:cell(action)="row">
                      <a
                        href="javascript:void(0);"
                        class="text-primary"
                        v-b-tooltip.hover
                        @click="editClick(row.item)"
                        title="Editer"
                      >
                        <i class="mdi mdi-pencil font-size-18"></i>
                      </a>
                      <a
                        href="javascript:void(0);"
                        class="text-danger"
                        v-b-tooltip.hover
                        @click="desactiverUser(row.item)"
                        title="Désactiver"
                      >
                        <i class="mdi mdi-close font-size-18"></i>
                      </a>
                      <a
                        href="javascript:void(0);"
                        class="text-warning mr-1"
                        @click="
                          messageShow(row.item, {
                            name: 'Notification',
                            value: 0,
                          })
                        "
                        v-b-tooltip.hover
                        title="Notification"
                      >
                        <i class="fas fa-bell"></i>
                      </a>
                     
                      <a
                        href="javascript:void(0);"
                        class="text-success"
                        @click="
                          messageShow(row.item, { name: 'Mail', value: 1 })
                        "
                        v-b-tooltip.hover
                        title="Mail"
                      >
                        <i class="fas fa-envelope"></i>
                      </a>
                      <a
                        href="javascript:void(0);"
                        class="text-primary ml-2"
                        @click="
                         openNewTab(row.item)
                        "
                        v-b-tooltip.hover
                        title="Détail"
                      >
                      <i class="fa fa-eye" aria-hidden="true"></i>
                      </a>
                    </template>

                    <template v-slot:cell(tel)="row" style="text-align: center">
                      <a
                        :href="'https://wa.me/' + row.value"
                        class="text-success"
                        v-b-tooltip.hover
                        title="Envoyer un message whatsapp"
                        target="_blank"
                      >
                        {{ row.value }}
                      </a>
                    </template>
                  </b-table>
                </div>
                <div class="row">
                  <div class="col">
                    <!--   <div
                      class="dataTables_paginate paging_simple_numbers float-right"
                    >
                      <ul class="pagination pagination-rounded mb-0">
                    
                        <b-pagination
                          v-model="currentPage"
                          :total-rows="rows"
                          :per-page="perPage"
                          :aria-controls="trajet"
                        ></b-pagination>
                      </ul>
                    </div> -->
                    <transition mode="out-in">
                      <div
                        class="dataTables_paginate paging_simple_numbers float-right"
                      >
                        <ul class="pagination pagination-rounded mb-0">
                          <!-- pagination -->
                          <li>
                            <span class="interval"> {{ startValue }}</span> -
                            <span class="interval">{{ endValue }}</span> sur
                            {{ totalValue }}
                          </li>
                          &nbsp;&nbsp;&nbsp;&nbsp;
                          <li>
                            <button
                              class="myBouton"
                              @click="firstVal()"
                              :disabled="startValue == 1"
                              data-toggle="tooltip"
                              data-placement="bottom"
                              title="Prem"
                            >
                              <ion-icon
                                name="play-skip-back-outline"
                              ></ion-icon>
                            </button>
                            <button
                              type="button"
                              class="myBouton"
                              @click="decrement()"
                              :disabled="endValue == 500"
                              data-toggle="tooltip"
                              data-placement="bottom"
                              title="Préc"
                            >
                              <ion-icon name="chevron-back-outline"></ion-icon>
                            </button>
                            <button
                              class="myBouton"
                              @click="increment()"
                              data-toggle="tooltip"
                              data-placement="bottom"
                              title="Suiv"
                            >
                              <ion-icon
                                name="chevron-forward-outline"
                              ></ion-icon>
                            </button>
                            <button
                              class="myBouton"
                              @click="incrementToLast()"
                              data-toggle="tooltip"
                              data-placement="bottom"
                              title="Dern"
                            >
                              <ion-icon
                                name="play-skip-forward-outline"
                              ></ion-icon>
                            </button>
                          </li>
                        </ul>
                      </div>
                    </transition>
                  </div>
                </div>
                <!--      <div class="row">
                  <h3 class="loading">
                    Loading   
                    <div class="loaading__dots animate" id="loaading__dots">
                      <span class="loading__dot"></span>
                      <span class="loading__dot"></span>
                      <span class="loading__dot"></span>
                      <span class="loading__dot"></span>
                     <span class="loading__dot-down"></span>
                    </div>
                  </h3>
                </div> -->
              </b-tab>
              <b-tab title-link-class="p-3">
                <template v-slot:title>
                  <a class="font-weight-bold active"
                    >Comptes désactivés : {{ rowsdataUserD }}</a
                  >
                </template>
                <div
                  class="rowd-flex justify-content-between align-items-center mt-4"
                >
                  <div class="p-3">
                    <div id="tickets-table_length" class="dataTables_length">
                      <label class="d-inline-flex align-items-center">
                        Afficher&nbsp;
                        <b-form-select
                          v-model="perPage"
                          size="sm"
                          :options="pageOptionsD"
                        ></b-form-select
                        >&nbsp;entrées
                      </label>
                    </div>
                  </div>

                  <!-- Search -->

                  <div class="inputCol p-3">
                    <div class="input">
                      <input
                        type="search"
                        id="serchInput"
                        placeholder="Rechercher..."
                        v-model="filter"
                      />
                    </div>
                  </div>

                  <!-- End search -->
                </div>
                <div class="table-responsive">
                  <b-table
                    id="trajetD"
                    style="cursor: pointer"
                    class="table-centered"
                    :items="dataUserD"
                    :fields="fieldsD"
                    responsive="sm"
                    :per-page="perPage"
                    :current-page="currentPage"
                    :sort-by.sync="sortBy"
                    :sort-desc.sync="sortDesc"
                    :filter="filter"
                    :filter-included-fields="filterOn"
                    @filtered="onFiltered"
                    @row-clicked="goToDetail"
                  >
                    <template v-slot:cell(badge)="row">
                      <div
                        class="d-flex justify-content-center"
                        :class="{
                          'fa fa-star': `${row.value}` === 'false',
                          'fa fa-star badge-soft-warning':
                            `${row.value}` === 'true',
                        }"
                      ></div>
                    </template>
                    <template v-slot:cell(statut)="row">
                      <div
                        class="badge font-size-12"
                        :class="{
                          'badge-soft-danger': `${row.value}` === 'Suspendu',
                          'badge-soft-success': `${row.value}` === 'Actif',
                        }"
                      >
                        {{ row.value }}
                      </div>
                    </template>
                    <template v-slot:cell(action)="row">
                      <a
                        href="javascript:void(0);"
                        class="text-success"
                        v-b-tooltip.hover
                        @click="activeUserPop(row.item)"
                        title="Activer"
                      >
                        <i class="mdi mdi-check font-size-18"></i>
                      </a>
                      <a
                        href="javascript:void(0);"
                        class="text-danger"
                        v-b-tooltip.hover
                        @click="deleteUserM(row.item)"
                        title="Supprimer"
                        v-if="row.item.trajet == 0"
                      >
                        <i class="fas fa-trash"></i>
                      </a>
                    </template>
                  </b-table>
                </div>
                <div class="row">
                  <div class="col">
                    <div
                      class="dataTables_paginate paging_simple_numbers float-right"
                    >
                      <ul class="pagination pagination-rounded mb-0">
                        <!-- pagination -->
                        <b-pagination
                          v-model="currentPageD"
                          :total-rows="rows"
                          :per-page="perPageD"
                          :rows="rowsinactif"
                        ></b-pagination>
                      </ul>
                    </div>
                    <!--    <div
                      class="dataTables_paginate paging_simple_numbers float-right"
                    >
                      <ul class="pagination pagination-rounded mb-0">
                        pagination
                        <li>1-500 sur 7000</li>
                        <li>
                          <ion-icon name="chevron-back-outline"></ion-icon
                          >&nbsp;&nbsp;&nbsp;&nbsp;<ion-icon
                            name="chevron-forward-outline"
                          ></ion-icon>
                        </li>
                      </ul>
                    </div> -->
                  </div>
                </div>
              </b-tab>
            </b-tabs>
          </div>
        </div>
      </div>
    </div>
    <b-modal
      id="modal-backdrop"
      centered
      v-model="showDelete"
      title="Voulez-vous vraiment supprimer cet utilisateur ?"
      title-class="font-18"
      hide-footer
    >
      <div class="row float-right mt-3">
        <b-button variant="danger" @click="showDelete = false">Non</b-button>
        <b-button variant="success" class="ml-3 mr-4" @click="deleteUser"
          >Oui</b-button
        >
      </div>
    </b-modal>

    <b-modal
      id="modal-backdrop"
      centered
      v-model="deleteModal"
      title="Voulez-vous vraiment réactiver cet utilisateur ?"
      title-class="font-18"
      hide-footer
    >
      <div class="row float-right mt-3">
        <b-button variant="danger" @click="deleteModal = false">Non</b-button>
        <b-button variant="success" class="ml-3 mr-4" @click="activeUser"
          >Oui</b-button
        >
      </div>
    </b-modal>

    <b-modal
      id="modal-center"
      centered
      size="xl"
      v-model="showEdit"
      title="Editer utilisateur"
      title-class="font-18"
      hide-footer
    >

      <form
        class="form-horizontal"
        role="form"
        method="post"
        @submit.prevent="ModifSubmit"
      >
        <div class="row">
          <div class="col-md-4 mb-3">
         
            <label for="lastname">Nom</label>
            <input
              type="text"
              class="form-control py-4"
              id="lastname"
              v-model="infoUser.lastname"
            />
          </div>
          <div class="col-md-4 mb-3">
            <label for="firstname">Prénom</label>
            <input
              type="text"
              class="form-control py-4"
              id="firstname"
              v-model="infoUser.firstname"
            />
          </div>

          <div class="col-md-4 mb-3">
            <label for="firstname">Profession</label>
            <input
              type="text"
              class="form-control py-4"
              id="profession"
              v-model="infoUser.profession"
            />
          </div>
          <div class="col-md-4 mb-12">
            <label for="firstname">Date de naissance</label>
            <input
              id=""
              v-model="infoUser.birthday"
              type="text"
              class="form-control"
              placeholder=""
              style="height: 50px"
            />
          </div>

          <div class="col-md-4 mb-3">
            <label for="lastname">Pays</label>
            <input
              type="text"
              class="form-control py-4"
              v-model="infoUser.pays"
            />
          </div>
          <div class="col-md-4 mb-3">
            <label for="lastname">Ville de résidence</label>
            <!--  <gmap-autocomplete
              style="height: 50px;"
              :value="infoUser.ville"
        
              class="form-control"
              id="lastname"
              placeholder="Ville de résidence"
              @place_changed="setDepartContry"
              :select-first-on-enter="true"
              @input="value = $event.target.value"
            >
            </gmap-autocomplete> -->

            <gmap-autocomplete
              :value="infoUser.ville"
              type="text"
              class="form-control"
              id="depart"
              placeholder="Ville de résidence"
              @place_changed="setDepartContry"
              :select-first-on-enter="true"
              style="height: 50px"
            >
            </gmap-autocomplete>
          </div>
          <div class="col-md-4 mb-3">
            <label for="lastname">Téléphone</label>
            <div class="row">
              <input
                type="text"
                class="form-control ml-2 mr-2"
                id="telephone"
                v-model="infoUser.indicatifTel"
                style="width: 30%; height: 50px"
              />
              <input
                type="text"
                class="form-control"
                id="lastname5"
                v-model="infoUser.telephone"
                style="width: 60%; height: 50px"
              />
            </div>
          </div>
          <div class="col-md-4 mb-3">
            <label for="firstname">Email</label>
            <input
              type="text"
              class="form-control py-4"
              id="firstname7"
              v-model="infoUser.email"
            />
          </div>
          <div class="col-md-4 mb-3">
            <label for="firstname">Personne à contacter</label>

            <input
              type="text"
              class="form-control"
              id="lastname5"
              v-model="infoUser.personneUrgence"
              style="height: 50px"
            />
          </div>
          <div class="col-md-4 mb-3">
            <label for="firstname">Contact de la personne à contacter</label>
            <div class="row">
              <input
                type="text"
                class="form-control ml-2 mr-2"
                id="telephone"
                v-model="infoUser.indicatifContactUrg"
                style="width: 30%; height: 50px"
              />
              <input
                type="text"
                class="form-control"
                id="lastname5"
                v-model="infoUser.contactUrgence"
                style="width: 60%; height: 50px"
              />
            </div>
          </div>
          <div class="col-md-4">
            <label class="control-label">Type d'utilisateur</label>
            <multiselect
              style="height: 50px !important"
              v-model="userTypeLocal"
              :options="userTypeList"
              label="value"
              track-by="id"
            >
            </multiselect>
          </div>
        </div>
        <button class="btn btn-success float-right" type="submit">
          Modifier
        </button>
      </form>
    </b-modal>

    <!-- ENVOYER LE MESSAGE NOTIF-->
    <b-modal v-model="showMessage" title="Nouveau Message" centered>
      <form>
        <div class="form-group">
          <label class="control-label">Choisir le type de message:</label>
          <multiselect
            v-model="messageSelect"
            :options="tyMessage"
            label="name"
            track-by="value"
          ></multiselect>
        </div>
        <div class="form-group">
          <input
            type="text"
            class="form-control"
            placeholder="Sujet"
            v-model="messageContent.sujet"
          />
        </div>
        <div class="form-group mb-2">
          <ckeditor
            v-model="messageContent.message"
            :editor="editor"
            aria-placeholder="Votre message"
          ></ckeditor>
          <!--   
          <textarea
            v-model="messageContent.message"
            placeholder="Votre message"
            class="form-control"
            id=""
            cols="30"
            rows="10"
          ></textarea>-->
        </div>
        <div class="form-group">
          <multiselect
            v-model="mediaSelect"
            :options="tyMedia"
            placeholder="Image, vidéo"
            label="name"
            track-by="value"
          ></multiselect>
        </div>
        <div class="form-group">
          <input
            type="text"
            class="form-control"
            v-model="url_link"
            placeholder="Lien du média"
          />
        </div>
      </form>
      <template v-slot:modal-footer>
        <b-button variant="secondary" @click="showMessage = false"
          >Fermer</b-button
        >
        <b-button variant="primary" @click="addMessage">
          Envoyer
          <i class="fab fa-telegram-plane ml-1"></i>
        </b-button>
      </template>
    </b-modal>
    <!-- -->

    <!-- ENVOYER LE MESSAGE MAIL-->
    <b-modal v-model="showMessageMail" title="Nouveau Message" centered>
      <form>
        <div class="form-group">
          <label class="control-label">Choisir le type de message:</label>
          <multiselect
            v-model="messageSelect"
            :options="tyMessage"
            label="name"
            track-by="value"
          ></multiselect>
        </div>
        <div class="form-group">
          <input
            type="text"
            class="form-control"
            placeholder="Sujet"
            v-model="messageContent.sujet"
          />
        </div>
        <div class="form-group mb-2">
          <ckeditor
            v-model="messageContent.message"
            :editor="editor"
            aria-placeholder="Votre Message"
          ></ckeditor>
        </div>
        <div class="form-group">
          <multiselect
            v-model="mediaSelect"
            :options="tyMedia"
            placeholder="Image, vidéo"
            label="name"
            track-by="value"
          ></multiselect>
        </div>
        <div class="form-group">
          <input
            type="text"
            class="form-control"
            v-model="url_link"
            placeholder="Lien du média"
          />
        </div>
      </form>
      <template v-slot:modal-footer>
        <b-button variant="secondary" @click="showMessage = false"
          >Fermer</b-button
        >
        <b-button variant="primary" @click="addMessage">
          Envoyer
          <i class="fab fa-telegram-plane ml-1"></i>
        </b-button>
      </template>
    </b-modal>
    <!-- -->
  </Layout>
</template>

<style>
thead {
  background: #3AAA35;
}thead div{
  color:#fff;
}
thead th:first-child {
  font-weight: 600 !important;
}
thead th span {
  color: #fff;
}

.nav-tabs .nav-link {
   background: #dc143c;
   transition: all .5s;
 }
  .nav-tabs .nav-link a{
  color: #fff ;
}

.text-red{
  color:#dc143c;
}  .nav-tabs .nav-link a{
  color: #fff!important ;
}

.text-green{
  color:#3AAA35;
}

.nav-tabs .nav-link a {
  color: rgba(44, 56, 74, 0.95);
}
.nav-tabs-custom .nav-item .nav-link.active a {
  color: #fff!important;
  font-weight: 600 !important;
  vertical-align: middle;
}
.nav-tabs-custom .nav-item .nav-link.active {
  font-weight: 600;
  background: #3AAA35!important;
  border-bottom: 1px solid #000000!important;
}
.nav-tabs-custom .nav-item .nav-link::after {
  border-bottom: 1px solid #000000!important;
}

/*table filter design*/

.input {

  width: 300px;
  height: 35px;
position: relative;
  box-shadow: 0px 3px 6px 0px rgb(0 0 0 / 10%), 0px 1px 3px 0px rgb(0 0 0 / 8%);
  background: #ffffff;
}

.input input {
  padding: 3px 3px 3px 8px;
  position: absolute;
  top: 0;
  width: 100%;
  height: 100%;
  border: none;
  outline: none;
  font-size: 18px;
  color: #495057;
  font-size: 1rem;
  line-height: 1.5;
  font-weight: 500;
}
.input input:focus {
  border: 3px solid #3aaa35;
}

.page-item.active .page-link {
  z-index: 3;
  color: #fff;
  background-color: #252b3b;
  border-color: #252b3b;
}
span.superuser {
  display: inline-flex;
  width: 20px;
  height: 20px;
  background-color: #fddb91;
  vertical-align: middle;
  border: none;
  box-shadow: -1px -1px 1px rgba(255, 255, 255, 0.95);
}
.dataTables_filter {
  padding-top: 0;
  margin-top: -10px;
}

.inputCol {
  position: relative;
  float: right;
}

.paging_simple_numbers {
  background: #3AAA35;
  box-shadow: 0 5px 15px rgba(0, 0, 0, 0.2);
  padding: 10px 30px;

  box-shadow: 6px 6px 10px -1px rgba(0, 0, 0, 0.15),
    -6px -6px 10px -1px rgba(255, 255, 255, 0.7);
} .paging_simple_numbers ul li{
  color:white!important;
}


.paging_simple_numbers ul li button{
  color:#fff!important;
}

.pagination ul {
  position: relative;
  display: flex;
  padding: 10px 20px;
  align-items: center;
}
.myIcon {
  margin-top: 2px;
}

.loading__dots {
  display: inline-flex;
  column-gap: 0.25rem;
}

.loading__dot {
  position: relative;
  width: 8px;
  height: 8px;
  background-color: hsl(0, 0%, 12%);
  border-radius: 50%;
}

.loading__dot:nth-child(1) {
  position: absolute;
  transform: scale(0);
}
.loading__dot:nth-child(4) {
  background: transparent;
}

.loading__dot-down {
  display: block;
  width: 8px;
  height: 8px;
  background-color: hsl(0, 0%, 12%);
  border-radius: 50%;
  transform: translate(0);
}

.animate .loading__dot:nth-child(1) {
  animation: scale-dot 0.8s 0.2s forwards;
}

.animate .loading__dot:nth-child(2),
.animate .loading__dot:nth-child(3) {
  animation: move-right 0.6s forwards;
}

.animate .loading__dot-down {
  animation: move-right-down 0.8s 0.2s forwards linear,
    move-down 2.8s 0.3s forwards ease-in;
}

@keyframes scale-dot {
  100% {
    transform: scale(1);
  }
}

@keyframes move-right {
  100% {
    transform: translateX (0.75rem);
  }
}

@keyframes move-right-down {
  50% {
    transform: translate(1.5rem, 0.25rem);
  }

  100% {
    transform: translate(2rem, 0.5rem);
  }
}
@keyframes move-down {
  100% {
    transform: translate(10rem, 80vh);
  }
}

.loading {
  font-size: 2rem;
  color: hsl(0, 0%, 12%);
}

.loading {
  font-weight: bold;
  font-size: 1.001rem;
  width: 100%;
  white-space: nowrap;
  color: hsl(0, 0%, 12%);
  text-align: center;
  display: inline-block;
  margin: 0 auto !important;
}
.otherThing {
  background-color: #f9edbe;
  border: 1px solid #f0c36d;
  width: 250px;
  height: 40px;
  margin-left: auto;
  margin-right: auto;
  margin-top: -55px;
}

.homeContainer{
  position: relative;
  
}
.containerDot {
  display: flex;
  justify-content: center;
  align-items: center;
  background-color: #f9edbe;
  border: 1px solid #f0c36d;
  font-size: 2rem;
  color: hsl(0, 0%, 12%);
  width: 250px;
  height: 40px;
  position: absolute;
  top: 2%;
  left: 0;
  right: 0;
  margin: 0 auto;
}

.dots {
  position: relative;
  width: 100px;
  height: 50px;
  transform: scale(0.25, 0.25);
  margin-left: -10px;
}
.dot {
  width: 30px;
  height: 30px;
  position: absolute;
  top: 50%;
  transform: translate(0, -40%);
  opacity: 0;
  border-radius: 50%;
  background-color: hsl(0, 0%, 12%);
}

.dot-1 {
  animation: animation-dots 1.5s infinite linear 1s;
}
.dot-2 {
  animation: animation-dots 1.5s infinite linear 0.5s;
}
.dot-3 {
  animation: animation-dots 1.5s infinite linear;
}

@keyframes animation-dots {
  0% {
    left: -100px;
    opacity: 0;
  }
  20% {
    left: 0px;
    opacity: 1;
  }
  80% {
    left: 80px;
    opacity: 1;
  }
  100% {
    opacity: 0;
    left: 150px;
  }
}

.rowBase {
  position: relative;
}

ion-icon {
  cursor: pointer;
}

.myBouton {
  background: transparent;
  text-align: center;
  outline: none;
  border: none;
}

/* .interval{
padding: 4px;
  height: 40px;
  min-width: 100px;
  width: 80px;
  text-align: center;
  color: #262626;
  background-color: #fff;
  border-radius: 4px;
  box-shadow: inset 0 5px 10px rgba(0, 0, 0, .1), 0 2px 5px rgba(0, 0, 0, .5);
  margin: 5px;

} */

.containerDot {
  z-index: 100;
}

.pac-container {
  z-index: 10000 !important;
}

/** Conducteur option */

.center input[type="checkbox"] {
  position: relative;
  width: 45px;
  min-width: 45px;
  height: 20px;
  -webkit-appearance: none;
  background: #c6c6c6;
  outline: none;
  border-radius: 20px;
  transition: -5px;
  cursor: pointer;
}

.center input:checked[type="checkbox"] {
  background: linear-gradient(0deg, #00d22d, #717171);
}

.center input[type="checkbox"]:before {
  content: "";
  position: absolute;
  width: 20px;
  height: 20px;
  border-radius: 50%;
  top: 0;
  left: 0;
  background: #fff;
  transform: scale(1, 1);
  box-shadow: 0 2px 5px rgba(0, 0, 0, 0.2);
  transition: 0.5s;
  color: #d6d2f8;
}
.center input:checked[type="checkbox"]:before {
  left: 25px;
}

.center {
  display: flex;
  align-items: center;
}
.mode {
  font-size: 16px;
  line-height: 20px;
  font-weight: 400;
  color: #495057;
}


.multiselect__tags{
  min-height: 50px!important;
  height: 50px;
}
</style>
